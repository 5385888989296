<template>
    <div v-if="isLoadingPage">
        <Quote title="Bu yerda siz parolni tiklashingiz mumkin"/>
        <GoBack next="Parolni tiklash"/>

        <div class="row justify-content-center">
            <div class="col-11 col-sm-11 col-md-8">
                <form v-if="!isTokenExpired" class="ABC" @submit.prevent="send">
                    <div class="input">
                        <label-component
                            label-for="exampleInputPassword1"
                            class="form-label mb-1"
                        >
                            Yangi parol kiriting
                            <strong v-if="!form.newPassword" class="invalidMessage text-danger">&#x2736;</strong>
                            <strong v-else-if="form.newPassword.length >= 6" class="text-success">&#10003;</strong>
                        </label-component>
                        <input-component
                            model-name="newPassword"
                            type="password"
                            class="form-control mb-2"
                            id="exampleInputPassword1"
                            :model-form="form"
                            @error="errors.newPassword"
                            required
                        />
                        <div class="error text-danger" style="font-size: 15px">
                            <p v-if="form.newPassword.length < 6 && form.newPassword">
                                Parol kamida 6ta belgidan iborat bo'lishi kerak
                            </p>
                        </div>
                    </div>
                    <div class="input">
                        <label-component
                            label-for="exampleInputPassword2"
                            class="form-label mb-1"
                        >
                            Yangi parolni qaytaring
                            <strong v-if="!form.repeatPassword" class="invalidMessage text-danger">&#x2736;</strong>
                            <strong v-else-if="form.repeatPassword === form.newPassword" class="text-success">
                                &#10003;
                            </strong>
                        </label-component>
                        <input-component
                            model-name="repeatPassword"
                            type="password"
                            id="exampleInputPassword2"
                            class="form-control mb-2"
                            :model-form="form"
                            @error="errors.repeatPassword"
                            required
                        />
                        <div class="error text-danger" style="font-size: 15px">
                            <p v-if="form.repeatPassword !== form.newPassword && form.repeatPassword">
                                Iltimos, to'g'ri parol kiriting
                            </p>
                        </div>
                    </div>
                    <div class="row d-md-flex justify-content-end">
                        <button class="btn btn-primary col-12 col-md-5 col-lg-4 col-xl-3 mt-4" :disabled="isDisabled"
                        >
                            Yuborish
                        </button>
                    </div>
                </form>
                <div v-if="isTokenExpired">
                    <p class="mt-3">
                        Ushbu havola allaqachon ishlatilgan yoki muddati tugagan,
                        <router-link to="/forgot-password" class="hover-decoration">qayta urinib ko'ring.</router-link>
                    </p>
                </div>

                <!--Modal qatorlar-->
                <div>
                    <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <h3>
                                Parolingiz muvaffaqiyatli o'zgartirildi!
                                Yangilangan parol bilan tizimga kirishingiz mumkin.
                            </h3>
                        </div>
                        <b-button
                            class="mt-3 float-end"
                            style="width: 100px"
                            variant="btn btn-primary"
                            size="md"
                            @click="hideSuccessModal; $router.push('/login')"
                        >
                            OK
                        </b-button>
                    </b-modal>
                </div>

                <div>
                    <b-modal ref="modal-unSuccess" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <h3>Xatolik yuz berdi, iltimos qayta urinib ko'ring</h3>
                        </div>
                        <b-button
                            class="mt-3 float-end"
                            style="width: 100px"
                            variant="btn btn-primary"
                            size="md"
                            @click="hideUnSuccessModal"
                        >
                            OK
                        </b-button>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Quote from "@/components/Quote";
import GoBack from "@/components/GoBack";
import LabelComponent from "@/components/LabelComponent.vue";
import InputComponent from "@/components/InputComponent.vue";
import {mapActions} from "vuex";

export default {
    name: "ResetPassword",
    components: {GoBack, Quote, LabelComponent, InputComponent},
    data() {
        return {
            isLoadingPage: false,
            modalText: '',
            isTokenExpired: false,
            form: {
                newPassword: '',
                repeatPassword: '',
            },
            errors: {
                newPassword: '',
                repeatPassword: '',
            },
            isLoading: false
        }
    },
    computed: {
        isDisabled() {
            return this.form.newPassword.length < 6 || this.form.newPassword !== this.form.repeatPassword
        }
    },
    methods: {
        ...mapActions(['fetchResetPassword', 'fetchCheckResetToken']),
        send() {
            this.isLoading = true;
            this.fetchResetPassword({
                newPassword: this.form.repeatPassword,
                resetToken: this.$route.params.token
            })
                .then((response) => {
                    this.isLoading = false;

                    if (response === 'Password successfully changed') {
                        this.$refs['modal-success'].show()
                        setTimeout(this.hideSuccessModal, 7000)

                    } else {
                        return response
                    }

                    this.isTokenExpired = true;
                })
                .catch((error) => {
                    this.modalText = error
                    this.$refs['modal-unSuccess'].show()
                    setTimeout(this.hideUnSuccessModal, 5000)
                    this.isLoading = false;
                })
        },
        hideSuccessModal() {
            this.$refs['modal-success'].hide()
            this.$router.push('/login')
        },
        hideUnSuccessModal() {
            this.$refs['modal-unSuccess'].hide()
        },
    },
    mounted() {
        this.$root.showOverlay = true;
        this.fetchCheckResetToken({refreshToken: this.$route.params.token})
            .then((response) => {
                if (!response.isAvailable) {
                    this.isTokenExpired = true
                }

                this.isLoadingPage = true
            })
            .finally(() => {
                this.$root.showOverlay =false
            })
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap');

* {
    transition: all 0.4s;
    padding: 0;
    margin: 0;
}

section form {
    font-family: 'Comfortaa', cursive;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
}

.input .form-control {
    border-radius: 10px !important;
    border: solid #80007F 1px !important;
    font-family: Comfortaa, cursive !important;
}

.btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    background-color: #80007F !important;
    border-color: #80007F;
    outline: none !important;
    color: #fff;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: none !important;
}

.btn-primary:hover {
    background-color: #990098 !important;
    border-color: #990098;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
#tel:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control {
    color: #000000;
    padding-left: 1.5rem;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.ABC {
    margin-top: 100px;
    margin-bottom: 100px;
}
</style>
